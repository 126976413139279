import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConnectionService} from './connection.service';


@Component({selector: 'app-root', templateUrl: './app.component.html', styleUrls: ['./app.component.scss']})
export class AppComponent implements OnInit {
    constructor(private formBuilder: FormBuilder, private connectionService: ConnectionService) {
    }

    get email() {
        return this.validatingForm.get('email');
    }

    get subject() {
        return this.validatingForm.get('subject');
    }

    get message() {
        return this.validatingForm.get('message');
    }

    get phone() {
        return this.validatingForm.get('phone');
    }

    get name() {
        return this.validatingForm.get('name');
    }

    submitted: boolean;

    private httpResponse: any;
    validatingForm: FormGroup;

    imagesBasic = [
        {img: 'assets/examples/photo45.jpg', thumb: 'assets/examples/photo45.jpg', description: 'Image 1'},
        {img: 'assets/examples/photo43.jpg', thumb: 'assets/examples/photo43.jpg', description: 'Image 2'},
        {img: 'assets/examples/photo27.jpg', thumb: 'assets/examples/photo27.jpg', description: 'Image 3'},
        {img: 'assets/examples/photo25.jpg', thumb: 'assets/examples/photo25.jpg', description: 'Image 4'},
        {img: 'assets/examples/photo9.jpg', thumb: 'assets/examples/photo9.jpg', description: 'Image 5'},
        {img: 'assets/examples/photo7.jpg', thumb: 'assets/examples/photo7.jpg', description: 'Image 6'},
        {img: 'assets/examples/photo58.jpg', thumb: 'assets/examples/photo58.jpg', description: 'Image 7'},
        {img: 'assets/examples/photo22.jpg', thumb: 'assets/examples/photo22.jpg', description: 'Image 8'},
        {img: 'assets/examples/photo30.jpg', thumb: 'assets/examples/photo30.jpg', description: 'Image 9'},
    ];

    @ViewChild('gallery', {static: true}) public el: any;


    ngOnInit() {
        const numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
        this.validatingForm = this.formBuilder.group({

            name: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+'), Validators.maxLength(15), Validators.minLength(4)]],
            phone: ['', [Validators.required, Validators.pattern(numericNumberReg), Validators.maxLength(10), Validators.minLength(10)]],
            email: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]{2,}[.][A-Za-z]{2,}$')]],
            subject: ['', [Validators.required, Validators.maxLength(15), Validators.minLength(4)]],
            message: ['', [Validators.required, Validators.maxLength(500), Validators.minLength(10)]]
        });

        this.connectionService.getPosts().subscribe(response => {
            this.httpResponse = response;
        });


    }

    onSubmit() {
        if (this.validatingForm.valid) {
            this.connectionService.sendMessage(this.validatingForm.value).subscribe(response => {
                alert('Your message has been sent.');
                this.validatingForm.reset();
                this.submitted = true;
            }, (error: any) => {
                console.log('Error', error);
            });
        } else {
            alert('Please make sure to complete the form.');
        }
    }

}

// onSubmit() {
//   // this.connectionService.sendMessage(this.validatingForm).subscribe(respo)
// }

// onSubmit() {
//   this.connectionService.sendMessage(this.validatingForm.value){
//     alert('Your message has been sent.');
//     this.validatingForm.reset();
//     this.submitted = true;
//   }, (error: any) => {
//     console.log('Error', error);
//   });
// }


// }
