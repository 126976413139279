import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Injectable({
    providedIn: 'root'
})

export class ConnectionService {
    url = 'https://api.onpointcolorado.com/prod/send';

    constructor(private http: HttpClient) {
    }

    sendMessage(formData: FormGroup) {
        const jsonData = JSON.stringify(formData);
        return this.http.post(this.url, jsonData);
    }

    getPosts(){
        return this.http.get(this.url);
    }
}
